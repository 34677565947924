/* eslint-disable vue/no-v-html */
/* eslint-disable */
<template>
  <div>
    <b-card title="Liste des offres">
      <div class="text-right mb-3">
        <b-select
          v-model="perPage"
          :options="pageOptions"
          style="width: fit-content"
        />
      </div>
      <b-table
        responsive
        show-empty
        empty-text="Aucune offre enrégistrée"
        hover
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(action)="data">
          <span
            class="cursor"
            @click="updateModal(data.item)"
          >
            <b-icon
              class="ml-1 text-warning"
              icon="pencil-square"
              font-scale="1.2"
            />
          </span>

          <span
            class="cursor"
            @click="deleteModal(data.item)"
          >
            <b-icon
              class="ml-1 text-danger"
              icon="trash"
              font-scale="1.2"
            />
          </span>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>

    <b-modal
      v-if="showUpdateModal"
      v-model="showUpdateModal"
      title="Modification du module"
      hide-backdrop
      :no-close-on-backdrop="process"
      :no-close-on-esc="process"
      centered
      hide-footer
      size="lg"
    >
      <b-tabs
        content-class="mt-3"
        justified
      >
        <b-tab title="Titre et description">
          <b-col sm="12">
            <b-form-group label="Titre">
              <b-form-input
                v-model="$v.title.$model"
                type="text"
                :class="{ 'is-invalid': $v.title.$error }"
                placeholder="Titre de l'actualité"
              />
              <div
                v-if="$v.title.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.title.required"
                >Le titre est requis.</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
          >
            <b-form-group label="Document illustratif">
              <b-form-file
                v-model="$v.file.$model"
                browse-text="Parcourir"
                placeholder="Choisir le fichier de l'appel d'offre"
                accept=".pdf"
                :class="{ 'is-invalid': $v.file.$error }"
              />
              <div
                v-if="$v.file.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.file.required"
                >Le document est requis.</span>

                <span
                  v-if="!$v.file.checkSize"
                >Le document est doit peser au maximum 2Mo.</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <quill-editor
              ref="myEditor"
              v-model="$v.content.$model"
              :options="snowOption"
              :class="{ 'is-invalid': $v.content.$error }"
            />
            <div
              v-if="$v.content.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.content.required"
              >La Description est requise.</span>
            </div>
            <b-row>
              <b-col sm="12">
                <div
                  v-if="process"
                  class="d-flex justify-content-center mt-3"
                >
                  <b-spinner
                    variant="primary"
                    label="Loading..."
                  />
                </div>
                <div class="text-right mt-4">
                  <b-button
                    variant="light"
                    :disabled="process"
                    @click="closeUpdateModal"
                  >
                    Retour
                  </b-button>
                  <b-button
                    type="submit"
                    variant="success"
                    class="ml-2"
                    :disabled="process"
                    @click="update"
                  >
                    Modifier
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-tab>
        <b-tab title="Détails de l'offre">
          <div>
            <p>
              <b-media>
                <template #aside>
                  <b-img
                    src="@/assets/images/pdf.jpg"
                    height="75"
                  />
                </template>
                <a
                  :href="getImage(file)"
                  :download="title"
                >Télécharger</a>
              </b-media>
            </p>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      title="Suppression de l'offre"
      hide-backdrop
      :no-close-on-backdrop="delProcess"
      :no-close-on-esc="delProcess"
      centered
      hide-footer
    >
      <div class="mt-2 mb-2 text-justify">
        Voulez-vous supprimer l'offre de titre {{ title }} ?
      </div>
      <hr class="my-3">
      <b-row>
        <b-col sm="12">
          <div
            v-if="delProcess"
            class="text-center mt-1 mb-1"
          >
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
          <div class="float-left">
            <b-button
              variant="secondary"
              :disabled="delProcess"
              @click="closeDeleteModal"
            >
              Retour
            </b-button>
          </div>
          <div class="float-right">
            <b-button
              type="submit"
              variant="outline-danger"
              class="ml-2"
              :disabled="delProcess"
              @click="deleteNew"
            >
              Supprimer
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean'],
]

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      loadeded: 1,
      loaded: false,
      items: [],
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'title', label: 'Titre' },
        { key: 'action', label: 'Action', class: 'text-center' },
      ],
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      showUpdateModal: false,
      showDeleteModal: false,
      content: '',
      contentHtml: null,
      snowOption: {
        theme: 'snow',
        placeholder: "Description de l'offre",
        modules: {
          toolbar: toolbarOptions,
        },
      },
      currentTender: null,
      file: null,
      images: [],
      title: null,
    }
  },
  validations: {
    title: {
      required,
    },
    content: {
      required,
    },
    file: {
      required,
      checkSize() {
        if (this.file != null) {
          const filesize = this.file.size
          const file = Math.round(filesize / 1024 / 1024)
          if (file <= 1.5) {
            return true
          }
          return false
        }
        return ' '
      },
    },
  },
  computed: {
    ...mapGetters({
      token: 'token',
      delSuccess: 'deleteTenderSuccess',
      delError: 'deleteTenderError',
      delProcess: 'deleteTenderProcess',

      success: 'updateTenderSuccess',
      error: 'updateTenderError',
      process: 'updateTenderProcess',
    }),
    cleanDescription() {
      return this.$sanitize(this.content)
    },
  },
  watch: {
    content(val) {
      if (val && this.showUpdateModal) {
        this.contentHtml = this.$refs.myEditor.quill.root.innerHTML
      }
    },
    success(val) {
      if (val) {
        this.closeUpdateModal()
        this.fetchData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Appel d'offre",
            text: 'Modifié avec succes.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Appel d'offre",
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    delSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.fetchData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Appel d'offre",
            text: 'Supprimé avec succes.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    delError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Appel d'offre",
            text: 'Une erreur est survenue lors de la suppression.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['deleteTender', 'updateTender']),
    getImage(item) {
      return `https://api-backend.sobemap.com/api/uploaded/${item}`
    },
    update() {
      this.$v.$touch()
      // eslint-disable-next-line no-empty
      if (this.$v.$anyError) {}
    },
    updateModal(item) {
      this.content = item.content
      this.title = item.title
      this.file = item.filename
      this.showUpdateModal = true
    },
    closeUpdateModal() {
      this.showUpdateModal = false
    },
    async fetchData() {
      this.loaded = true
      this.items = await this.$http({
        method: 'get',
        url: `/api/micro/tender/list-paged/${this.currentPage}/${this.perPage}`,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        this.totalItems = res.data.total_count
        this.loaded = false
        return res.data.items
      })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Appel d'offre",
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteModal(item) {
      this.currentTender = item.id.uid
      this.title = item.title
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    deleteNew() {
      this.deleteTender({
        id: this.currentTender,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
@import '@core/scss/vue/libs/quill.scss';
</style>
